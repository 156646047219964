import React from "react";
import ClearSearchLink from "./ClearSearchLink";

const Header = () => (
  <div>
    <h1 className="title">
      <ClearSearchLink className="title__link">
        <span className="title-part">Dutch</span>
        <span className="title-part">Physics</span>
      </ClearSearchLink>
    </h1>

    <p className="intro-text">
      This website aims to give a complete overview of all physics research in
      The Netherlands.
    </p>

    <p className="intro-text">Type a keyword and start exploring:</p>
  </div>
);

export default Header;
