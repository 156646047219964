import React, { Component } from "react";

import { Redirect } from "react-router-dom";
//withRouter

// import { ActiveKeywordContext } from "./ActiveKeywordProvider";

class KeywordLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      linkClicked: false
    };
  }

  // Not necessary anymore?
  // componentWillReceiveProps(nextProps) {
  //   console.log("Next props: " + nextProps.location);
  //   console.log(this.props.location, nextProps.location);
  //   if (nextProps.location !== this.props.location) {
  //     // navigated!
  //   }
  //   //TODO
  //   this.setState({
  //     linkClicked: false
  //   });
  // }

  onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    const searchTerm = e.target.innerText;
    // console.log("Clicked: " + searchTerm);

    // Deactivate any active keywords
    // const { deactivateKeyword } = this.context;
    // deactivateKeyword();

    this.setState({
      linkClicked: true
    });
  };

  keywordRoute() {
    //TODO: replace special characters
    return `/${this.props.keyword.label}`;
  }

  render() {
    const { keyword, children, ...otherProps } = this.props;
    const { linkClicked } = this.state;

    if (linkClicked === true) {
      console.log(this.keywordRoute());

      // key = {`from-${keyword.id}`
      return <Redirect to={this.keywordRoute()} />;
    }

    return (
      <a
        className={this.props.className}
        href={this.keywordRoute()}
        onClick={this.onClick}
        {...otherProps}
      >
        {children || keyword.label}
      </a>
    );
  }
}

// KeywordLink.contextType = ActiveKeywordContext;

export default KeywordLink;
