import React from "react";

const AboutUs = () => (
  <div className="content_page content_page--about_us">
    <h2>About us</h2>
    <p>
      The website www.dutchphysics.org is owned by the foundation 
      Dutch Physics Platform (DPP), in which the ‘Platform 
      Academische Natuurkunde’ (PAN, Platform Academic Physics), {' '}
      <a href="https://www.nwo.nl/en/about-nwo/organisation/nwo-domains/enw"  target="_blank" rel="noopener noreferer">
        NWO Domain Science
      </a>{' '} and the 
      {' '}<a href="http://www.nnv.nl/" target="_blank" rel="noopener noreferer">
        Netherlands’ Physical Society (NNV)
      </a>{' '} cooperate.
    </p>

    <h3>Editorial board of www.dutchphysics.org:</h3>
    <ul>
      <li>Kristina Ganzinger (AMOLF)</li>
      <li>Stefan Witte (ARCNL)</li>
      <li>Anja Bieberle (DIFFER)</li>
      <li>Thomas Peitzmann (Nikhef)</li>
      <li>Vacancy (RU)</li>
      <li>Erik van der Giessen (RUG)</li>
      <li>Josefine Proll (TU/e)</li>
      <li>Gary Steele (TUD)</li>
      <li>Luca Giomi (UL)</li>
      <li>Ron Heeren (UM)</li>
      <li>Guus Rijnders (UT)</li>
      <li>Laura Filion (UU)</li>
      <li>Alejandra Castro (UvA)</li>
      <li>Elisabeth von Hauff (VU)</li>
      <li>Joshua Dijksman (WUR)</li>
    </ul>

    <h3>Board members of the foundation Dutch Physics Platform:</h3>
    <p>
      Chair: Mark Golden (chair PAN) <br/>
      Secretary: Arlette Werner (NWO) <br/>
      Treasurer: Noortje de Graaf (director NNV) <br/>
    </p>

    <p>
      Netherlands Chamber of Commerce number: 71774815<br/>
      Bank account (IBAN): NL85TRIO0379284804
    </p>

    <h3>Contact: </h3>
    <p>
      Noortje de Graaf <br/>
      E: <a href="mailto: directeur@nnv.nl">directeur@nnv.nl</a> <br/>
      T: +31 (0)20-592 2211
    </p>

    <p>
      This website is designed by Roos Beeldt and built by{" "}
      <a href="https://www.miniem.nl">Miniem</a>.
    </p>
  </div>
);

export default AboutUs;
