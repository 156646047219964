import React, { Component } from "react";

import ResultItem from "./ResultItem";

class ResultList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const searchTerm = this.props.searchTerm;
    const nodes = this.props.groupNodes;

    return (
      <div className="">
        {!searchTerm ? (
          <div className="results__no-results__enter-search-term">
            <p>
              <span className="results__no-results__left-arrow">&larr;</span>{" "}
              <span className="results__no-results__up-arrow">&uarr;</span>{" "}
              Enter a search term to get started {searchTerm}
            </p>
          </div>
        ) : null}

        {searchTerm && nodes
          ? nodes.map((node, index) => (
              <ResultItem key={node.id} node={node} theme="list" />
            ))
          : null}
      </div>
    );
  }
}

export default ResultList;
