import React, { Component } from "react";

import { Query } from "react-apollo";
import { GET_FULL_RESULTS, GET_PARTIAL_RESULTS } from "queries/searchQuery";

import ResultList from "./ResultList";
import ResultMap from "./ResultMap";
import ResultCountView from "./ResultCountView";
import ResultViewToggler from "./ResultViewToggler";

import ClearSearchLink from "./ClearSearchLink";

class Results extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resultView: "map"
    };
  }

  onResultViewToggle = newView => {
    this.setState({ resultView: newView });
  };

  render() {
    const searchTerm = this.props.searchTerm;

    const searchQueryType = searchTerm ? GET_FULL_RESULTS : GET_PARTIAL_RESULTS;

    return (
      <Query query={searchQueryType} variables={{ searchTerm: searchTerm }}>
        {({ loading, error, data }) => {
          const searchTerm = this.props.searchTerm;

          // TODO: make nice loading indicator
          if (loading) return <p className="results__loading">Loading...</p>;

          if (error) {
            return (
              // TODO: only display error messages in dev
              <pre>
                Bad:{" "}
                {error.graphQLErrors.map(({ message }, i) => (
                  <span key={i}>{message}</span>
                ))}
              </pre>
            );
            return <p>Error :(</p>;
          }

          const { nodes, keywords, rootNodes } = data.search;

          const groupNodes = nodes;

          // Create rootNode.groups array containing groups per rootNode
          rootNodes.forEach(rootNode => {
            rootNode.groupNodes = [];
          });

          groupNodes.forEach(node => {
            // find root node for this group
            const rootNode = rootNodes.filter(
              rootNode => rootNode.id == node.rootId
            )[0];
            rootNode.groupNodes.push(node);
          });

          // Als er wel keywords zijn maar geen resultaten zin
          if (!groupNodes.length && keywords.length) {
            // dan deze error tonen.
            // Als er ook geen keywords zijn gevonden verschijnt er een error onder
            // search form (in KeywordList component)

            return (
              <div>
                <div className="results__no-results">
                  It seems like nobody in the Netherlands is researching
                  {keywords.length == 1 ? " this topic." : " these topics."} An
                  opportunity for you?
                </div>
                <div className="results__no-results__try-else">
                  <p>
                    <span className="results__no-results__left-arrow">
                      &larr;
                    </span>{" "}
                    <span className="results__no-results__up-arrow">
                      &uarr;
                    </span>{" "}
                    Try a different search term
                  </p>
                </div>
              </div>
            );
          }

          // Don't show count view when "view as list" is selected
          // and no search term has been submitted
          const showCountView = this.state.resultView == "map" || searchTerm;

          return (
            <div>
              <div className="results_topbar">
                {showCountView ? (
                  <ResultCountView
                    rootNodes={rootNodes}
                    groupNodes={groupNodes}
                  />
                ) : null}
                <ResultViewToggler
                  onToggle={this.onResultViewToggle}
                  current={this.state.resultView}
                />
              </div>

              <div className="results__current_filter">
                {searchTerm ? (
                  <div>
                    <span className="results__current_filter__label">
                      Search filter:
                    </span>{" "}
                    <span className="results__current_filter__search-term">
                      {searchTerm}
                      <ClearSearchLink className="results__current_filter__clear">
                        x
                      </ClearSearchLink>
                    </span>
                  </div>
                ) : null}
              </div>

              {this.state.resultView == "map" ? (
                <ResultMap rootNodes={rootNodes} groupNodes={groupNodes} />
              ) : (
                <ResultList
                  rootNodes={rootNodes}
                  groupNodes={groupNodes}
                  searchTerm={searchTerm}
                />
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

export default Results;
