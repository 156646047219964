// Old browser support
// import "core-js/es/map";
// import "core-js/es/set";

// ES5 fetch support
import "@babel/polyfill";
import "unfetch/polyfill";

import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

// apollo/graphql
import ApolloClient from "apollo-boost";

// components
import Header from "./Header";
import Results from "./Results";
import KeywordList from "./KeywordList";
import KeywordSuggestions from "./KeywordSuggestions";
import SearchForm from "./SearchForm";
import FooterMenu from "./FooterMenu";
import CookieConsent from "react-cookie-consent";

// context
import SearchOptionsProvider from "./SearchOptionsProvider";
import ActiveKeywordProvider from "./ActiveKeywordProvider";

const csrfToken = document
  .querySelector("meta[name=csrf-token]")
  .getAttribute("content");

export const client = new ApolloClient({
  credentials: "same-origin",
  headers: {
    "X-CSRF-Token": csrfToken
  }
});


// TODO: route dingen hierheen verplaatsen? of toch in App.jsx laten zitten?
// import VacancyList, { ExpiredVacancyList } from "./VacancyList";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


class MainApp extends Component {
  extractSearchTerm(location) {
    // workaround om bij AboutUs niet "about" als zoekterm te hebben
    // Dit werkt niet, snap nog niet waarom
    // if (this.props.contentPageComponent) {
    //   return "";
    // }
    // Daarom maar zo:
    if (["/about", "/vacancies", "/closed-vacancies"].includes(location)) {
      return "";
    }
    return location.replace(/^[/]/, "").replace("&sol;", "/"); //replace first slash (turn `/searchTerm` into `searchTerm` )
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    // if (this.props.contentPageComponent) {
    //   console.log("C");
    // }

    const initialSearchTerm = this.extractSearchTerm(
      props.match.params[0] || ""
    );

    this.state = {
      searchTerm: initialSearchTerm,
      activeKeywordId: null
    };
  }

  onRouteChanged() {
    // console.log("ROUTE CHANGED");
    this.closeAllPopups();
  }

  componentDidUpdate(prevProps) {
    // console.log("Did update");
    // console.log(this.props);

    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown, false);
  }

  activateKeyword = id => {
    this.setState({
      activeKeywordId: id
    });
  };

  deactivateKeyword = () => {
    this.setState({
      activeKeywordId: null
    });
  };

  closeAllPopups = () => {
    // TODO: should also close Group popups

    this.setState({
      activeKeywordId: null
    });
  };

  // TODO
  // Workaround for getting path update changes after Redirects
  // Has something to do with route matching *
  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      // navigated!
      const newSearchTerm = this.extractSearchTerm(nextProps.location.pathname);
      this.setState({
        searchTerm: newSearchTerm //triggers new search
      });
    }
  }

  render() {
    // const { match, location, history } = this.props;

    // todo: dit is kak, via props is wel echt beter
    // fixen als react routes met search gefixt zijn
    const { searchTerm } = this.state;

    // contentComponent is passed by the router
    // currently the only content page is <AboutUs />
    // but that might change in the future
    const { contentPageComponent } = this.props;

    return (
      <SearchOptionsProvider value={{ searchTerm: searchTerm }}>
        <ActiveKeywordProvider
          value={{
            activeKeywordId: this.state.activeKeywordId,
            activateKeyword: this.activateKeyword,
            deactivateKeyword: this.deactivateKeyword
          }}
        >
          <div className="background background--left" />
          <div className="background background--right" />
          <div className="grid-container" onClick={this.closeAllPopups}>
            <div>
              <div className="container__left">
                <Header />

                <SearchForm searchTerm={searchTerm} />

                <div className="keywords-list">
                  {searchTerm ? (
                    <KeywordList searchTerm={searchTerm} />
                  ) : (
                    <KeywordSuggestions showTitle={true} hideOnMobile={true} />
                  )}
                </div>

                <FooterMenu className="footer_menu--default-screen" />
              </div>

              <div className="container__right">
                {/* <Route
                  path="/vacancies"
                  render={props => (
                    <VacancyList />
                  )}
                /> */}

                {contentPageComponent ? (
                  contentPageComponent()
                ) : (
                  <Results searchTerm={searchTerm} />
                )}

                <FooterMenu className="footer_menu--small-screen" />
              </div>

            </div>
            <CookieConsent
              buttonStyle={{ background: "#ef5513", color: "white" }}
            >
              This website uses analytical cookies to enhance the user
              experience.
            </CookieConsent>
          </div>
        </ActiveKeywordProvider>
      </SearchOptionsProvider>
    );
  }
}

export default withRouter(MainApp);
