import React, { Component } from "react";
import { Query } from "react-apollo";

import KeywordListItem from "./KeywordListItem";
import { GET_NODE_DETAILS } from "../queries/nodeDetailsQuery";

class ResultItem extends Component {
  constructor(props) {
    super(props);
  }

  render_breadcrumbs = node => {
    const { theme } = this.props;
    // console.log(node);
    let ancestors = node.ancestors;

    // exclude the root node from the breadcrumbs on map view
    if (theme == "map") {
      ancestors = ancestors.filter(node => node.depth != 0);
    }

    return (
      <div className="group-item__breadcrumbs">
        {ancestors.map((ancestor, index) => {
          return (
            <span key={index}>
              {ancestor.url ? (
                <a
                  href={ancestor.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {ancestor.name}
                </a>
              ) : (
                ancestor.name
              )}{" "}
              {index < ancestors.length - 1 ? <span>&rarr;</span> : null}{" "}
            </span>
          );
        })}
      </div>
    );
  };

  render_body = node => {
    const { theme } = this.props;

    return (
      <div>
        <h3 className="group-item__title">{node.name}</h3>
        {this.render_breadcrumbs(node)}
        <p className="group-item__pi-name">PI: {node.piName}</p>
        <p className="group-item__keyword-list-title">
          {!node.keywords ? 0 : node.keywords.length} research subjects:
        </p>
        {node.keywords && node.keywords.length > 0 ? (
          <ul className="keyword-list">
            {node.keywords.map((keyword, index) => (
              <KeywordListItem
                key={keyword.id}
                keyword={keyword}
                theme="on-light-background"
                showDescendants={false}
                listName={theme}
              />
            ))}
          </ul>
        ) : null}
        {node.url ? (
          <div className="group-item__visit-website-container">
            <a
              className="group-item__visit-website"
              href={node.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit website
            </a>
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    // const props = this.props;
    const { node, theme } = this.props;

    // Are node details queried in search query or is a NODE_DETAILS query needed?
    const nodeDetailsQueried = node.parents || node.keywords;

    return (
      <div
        className={`group-item ${theme ? `group-item--${theme}-view` : null}`}
      >
        {!nodeDetailsQueried ? (
          <Query query={GET_NODE_DETAILS} variables={{ nodeId: node.id }}>
            {({ loading, error, data }) => {
              // TODO: make nice loading indicator
              if (loading) return <p>Loading...</p>;

              if (error) {
                return (
                  // TODO: only display error messages in dev
                  <pre>
                    Bad:{" "}
                    {error.graphQLErrors.map(({ message }, i) => (
                      <span key={i}>{message}</span>
                    ))}
                  </pre>
                );
                return <p>Error :(</p>;
              }

              const { node } = data;

              return this.render_body(node);
            }}
          </Query>
        ) : (
          this.render_body(node)
        )}
      </div>
    );
  }
}
export default ResultItem;
