import React, { Component } from "react";

import { SearchOptionsContext } from "./SearchOptionsProvider";

class ResultGroupList extends React.Component {
  constructor(props) {
    super(props);

    this.onGroupClick = props.onGroupClick;

    this.state = {
      viewType: "tree",
      activeGroupLists: [],
      nodeTree: this.getNodeTree()
    };
  }

  activateGroupListForNode = node => {
    this.setState(prevState => ({
      activeGroupLists: [...prevState.activeGroupLists, node.id]
    }));
  };

  showGroupListForNode = node => {
    return this.state.activeGroupLists.includes(node.id);
  };

  getNodeTree = () => {
    const nodeTree = [];

    const groupNodes = this.props.groupNodes;

    groupNodes.forEach(groupNode => {
      let i = 0;

      let previousAncestor = null;

      groupNode.ancestors.forEach(ancestor => {
        // Add rootnode(s) to rootNodes object
        if (ancestor.depth == 0 && !nodeTree.includes(ancestor)) {
          nodeTree.push(ancestor);
        }

        // Put this ancestor in its parent's children array
        if (previousAncestor && !previousAncestor.children.includes(ancestor)) {
          previousAncestor.children.push(ancestor);
        }

        // Prepare this object to save relations to its children
        if (ancestor.children === undefined) {
          ancestor.children = [];
        }

        // Make this ancestor the next `previousAncestor`
        previousAncestor = ancestor;
      });

      if (!previousAncestor.children.includes(groupNode)) {
        previousAncestor.children.push(groupNode);
      }
    });

    return nodeTree;
  };

  // recursive
  renderChildrenForNode = node => {
    // Are we rendering group children?
    // If so, first check if thes groups are toggled active
    // (groups don't have a children attribute)
    const childrenAreGroups = node.children[0].children === undefined;

    if (childrenAreGroups && !this.showGroupListForNode(node)) {
      return (
        <li>
          <a
            href=""
            className="mapitem__group-list__toggle-link"
            onClick={e => {
              e.preventDefault();
              this.activateGroupListForNode(node);
            }}
          >
            {node.children.length} Groups/PI's <span>show</span>
          </a>
        </li>
      );
    }

    return node.children.map((child, index) => {
      const childHasChildren =
        child.children !== undefined && child.children.length > 0;

      return (
        <li key={index} className="mapitem__group-list__item">
          {child.isGroup ? (
            <a
              className="mapitem__group-list__link"
              href=""
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.onGroupClick(child.id);
              }}
            >
              {child.name}
            </a>
          ) : (
            child.name
          )}

          {childHasChildren ? (
            <ul>{this.renderChildrenForNode(child)}</ul>
          ) : null}
        </li>
      );
    });
  };

  renderCollapsedGroupTree = () => {
    const nodeTree = this.state.nodeTree;

    const rootNode = nodeTree[0];

    if (!rootNode) {
      console.log("No root node found");
      return <ul />;
    }

    return this.renderChildrenForNode(rootNode);
  };

  // The old "default" list view
  renderGroupList = () => {
    const groupNodes = this.props.groupNodes;

    return groupNodes.map((groupNode, index) => (
      <li key={index} className="mapitem__group-list__item">
        <a
          className="mapitem__group-list__link"
          href=""
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.onGroupClick(groupNode.id);
          }}
        >
          {groupNode.name}
        </a>
      </li>
    ));
  };

  activateTreeView = e => {
    e.preventDefault();
    this.setState({
      viewType: "tree"
    });
  };

  activateListView = e => {
    e.preventDefault();
    this.setState({
      viewType: "list"
    });
  };

  render() {
    const groupNodes = this.props.groupNodes;

    const viewType = this.state.viewType;

    return (
      <div>
        <p className="mapitem__group-list-title">
          {
            /* Try to make clear if this was a search result (vs all groupNodes of this institute) */
            <SearchOptionsContext.Consumer>
              {searchOptions =>
                searchOptions.searchTerm ? "Found " + groupNodes.length : null
              }
            </SearchOptionsContext.Consumer>
          }{" "}
          {groupNodes.length == 1 ? "Group/PI" : "Groups/PI's"}
          {
            <span className="mapitem__grouplist-toggler">
              <a
                className={`mapitem__grouplist-toggler__link ${
                  viewType == "tree"
                    ? "mapitem__grouplist-toggler__link--active"
                    : ""
                }`}
                onClick={this.activateTreeView}
              >
                Tree view
              </a>{" "}
              <a
                className={`mapitem__grouplist-toggler__link ${
                  viewType == "list"
                    ? "mapitem__grouplist-toggler__link--active"
                    : ""
                }`}
                onClick={this.activateListView}
              >
                List view
              </a>{" "}
            </span>
          }
        </p>
        <ul className="mapitem__group-list">
          {viewType == "tree"
            ? this.renderCollapsedGroupTree()
            : this.renderGroupList()}
        </ul>
      </div>
    );
  }
}

export default ResultGroupList;
