import React from "react";

export const ActiveKeywordContext = React.createContext({
  activeKeywordId: null,
  activateKeyword: () => {},
  deactivateKeyword: () => {}
});

export default function ActiveKeywordProvider(props) {
  return <ActiveKeywordContext.Provider {...props} />;
}
