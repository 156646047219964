import React, { Component } from "react";
import { SearchOptionsContext } from "./SearchOptionsProvider";
import { ActiveKeywordContext } from "./ActiveKeywordProvider";
import KeywordTree from "./KeywordTree";
import KeywordLink from "./KeywordLink";
import KeywordLabel from "./KeywordLabel";

class KeywordListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      linkClicked: false
    };
  }

  getUniqueId = () => {
    // ID of this unique keyword
    // (should not be same as same keyword appearing elsewhere)
    const { listName, keyword } = this.props;
    return listName + keyword.id;
  };

  onClick = e => {
    e.preventDefault();
    e.stopPropagation();

    let { activateKeyword } = this.context;

    const uniqueId = this.getUniqueId();
    activateKeyword(uniqueId);

    // Na loaden -> scroll into view
    // (for map view smallest screens )
    const smallestScreenBreakpoint = 670;
    if (
      window.innerWidth < smallestScreenBreakpoint &&
      this.props.listName == "map"
    ) {
      this.timer = setTimeout(() => this.scrollIntoView(), 500);
    }
  };

  scrollIntoView = () => {
    const keywordDetailsElementId = "keyword-details-" + this.getUniqueId();

    document.getElementById(keywordDetailsElementId).scrollIntoView(true);
  };

  onClose = e => {
    e.preventDefault();
    e.stopPropagation();
    let { deactivateKeyword } = this.context;
    deactivateKeyword();
  };

  render() {
    const {
      theme = "on-dark-background",
      listName,
      keyword,
      showGroupCount
    } = this.props; //.theme || "on-dark-background";
    const parents = this.props.keyword.parents || [];
    const uniqueId = this.getUniqueId();

    // Get keyword activation function and current active id from context
    const { activeKeywordId } = this.context;

    return (
      <li
        className={`keyword-list__item keyword-list__item--${theme}`}
        onClick={this.onClick}
      >
        <a href="#">
          <KeywordLabel keyword={keyword} showGroupCount={showGroupCount} />
        </a>
        {parents.length ? (
          <span
            className={`keyword-list__item__parent-item keyword-list__item__parent-item--${theme}`}
          >
            {" "}
            (in:{" "}
            {parents.map((parent, index) => (
              <span key={index}>
                <KeywordLink key={index} keyword={parent} />
                {index != parents.length - 1 ? "," : null}{" "}
              </span>
            ))}
            )
          </span>
        ) : null}

        {/* Show keyword if clicked Keyword is this and only this keyword 
                to make sure it disappears when other keyword is clicked */}

        {activeKeywordId === uniqueId ? (
          // TODO: refactor into separate component or function

          <div
            className={`keyword_details keyword_details--${listName}-view`}
            id={"keyword-details-" + uniqueId}
          >
            <p className="keyword_details__title">About {keyword.label}</p>

            <a className="keyword_details__close-cross" onClick={this.onClose}>
              x
            </a>

            <p className="keyword_details__groups_summary">
              {keyword.groupNodeCount || "No"} {" Dutch "}
              {keyword.groupNodeCount == 1 ? "group" : "groups"}{" "}
              {keyword.groupNodeCount == 1 ? "is" : "are"} researching this
              topic{" "}
              {keyword.groupNodeCount ? (
                <SearchOptionsContext.Consumer>
                  {searchOptions =>
                    searchOptions.searchTerm != keyword.label ? (
                      <KeywordLink
                        keyword={keyword}
                        className="keyword_details__groups_summary__link"
                      >
                        (show)
                      </KeywordLink>
                    ) : null
                  }
                </SearchOptionsContext.Consumer>
              ) : null}
            </p>

            <KeywordTree
              keyword={keyword}
              showDescendants={this.props.showDescendants}
            />

            <a className="keyword_details__close-button" onClick={this.onClose}>
              &larr; Back
            </a>
          </div>
        ) : null}
      </li>
    );
  }
}

// Assign context
KeywordListItem.contextType = ActiveKeywordContext;

export default KeywordListItem;
