import React, { Component } from "react";
import KeywordListItem from "./KeywordListItem";
import KeywordSuggestions from "./KeywordSuggestions";

import { Query } from "react-apollo";
import { GET_PARTIAL_RESULTS } from "queries/searchQuery";

class KeywordList extends Component {
  render() {
    const searchTerm = this.props.searchTerm;

    return (
      <div >
        <Query
          query={GET_PARTIAL_RESULTS}
          variables={{ searchTerm: searchTerm }}
        >
          {({ loading, error, data }) => {
            // TODO: make nice loading indicator
            if (loading)
              return <p className="keywords-loading-indicator">Loading...</p>;

            if (error) {
              return (
                // TODO: only display error messages in dev
                <pre>
                  Bad:{" "}
                  {error.graphQLErrors.map(({ message }, i) => (
                    <span key={i}>{message}</span>
                  ))}
                </pre>
              );
              return <p>Error :(</p>;
            }

            //console.log(data);

            const { keywords, nodes } = data.search;

            // If 0 keywords and 0 nodes -> show "please try different keyword" message
            // If 0 keywords but >0 nodes -> nodes matched by name instead of keyword, so don't show anything here.
            if (keywords.length == 0 && nodes.length == 0) {
              return (
                <div>
                  {/* <h3 className="subtitle">No results</h3> */}
                  <p className="keywords-not-found-text">
                    No keywords found for {searchTerm}. Please try something
                    else.
                  </p>
                  <p className="keywords-not-found-text">
                    Here are some suggestions:
                  </p>

                  <KeywordSuggestions showTitle={false} />
                </div>
              );
            }

            return (
              <div>
                {keywords.length > 0 ? (
                  <div>
                    <h3 className="keywords-list-title">
                      {this.props.title ? this.props.title : "Keywords"}:
                    </h3>
                    <ul className="keyword-list keyword-list--all-matches">
                      {keywords.map((keyword, index) => (
                        <KeywordListItem
                          key={keyword.id}
                          keyword={keyword}
                          showGroupCount={true}
                          listName="all-matches"
                        />
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default KeywordList;
