import gql from "graphql-tag";

const GET_ALL_VACANCIES = gql`
  query allVacanciesQuery($active: Boolean!) {
    vacancies(active: $active) {
      title
      node
      organizationName
      url
      createdAt
    }
  }
`;

export default GET_ALL_VACANCIES;
