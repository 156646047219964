import React, { Component } from "react";

class ResultCountView extends Component {
  render() {
    const groupNodes = this.props.groupNodes;
    const rootNodes = this.props.rootNodes;

    return (
      <div className="results_topbar__summary">
        <span className="results_topbar__summary__count">
          {groupNodes.length}
        </span>{" "}
        groups in{" "}
        <span className="results_topbar__summary__count">
          {rootNodes.length}
        </span>{" "}
        institutions
      </div>
    );
  }
}

export default ResultCountView;
