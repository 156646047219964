import React, { Component } from "react";

import { GET_KEYWORD_DETAILS } from "../queries/keywordDetailsQuery";

import { Query } from "react-apollo";
import KeywordLink from "./KeywordLink";

// Props: keyword
class KeywordTree extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    // keyword does not contain parents and children yet
    const keyword = this.props.keyword;

    return (
      <div className="keyword_tree">
        <Query
          query={GET_KEYWORD_DETAILS}
          variables={{ keywordId: keyword.id }}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return <div>Loading...</div>;
            }

            if (error) {
              return (
                // TODO: only display error messages in dev
                <pre>
                  Bad:{" "}
                  {error.graphQLErrors.map(({ message }, i) => (
                    <span key={i}>{message}</span>
                  ))}
                </pre>
              );
              return <p>Error :(</p>;
            }

            //console.log(data);

            const {
              keyword: { ancestors, descendants }
            } = data;

            const numberOfAncestorLevels = ancestors.length
              ? Math.max(...ancestors.map(ancestor => Math.abs(ancestor.level)))
              : 0;

            /*const numberOfDescendantLevels = descendants.length ?
            Math.max(
              ...descendants.map(descendant => Math.abs(descendant.level))
            ) : 0;*/

            console.log(ancestors.length + descendants.length);
            if (ancestors.length + descendants.length == 0) {
              return null;
            }

            return (
              <div className="tree">
                <h5 className="tree__title">Related keywords:</h5>
                <ul className="tree__list">
                  {ancestors &&
                    ancestors
                      .sort((a, b) => b.level - a.level)
                      .map((ancestor, index) => {
                        return (
                          <li
                            key={index}
                            className={`tree__item tree__item--indent-${Math.abs(
                              numberOfAncestorLevels - ancestor.level
                            )}`}
                          >
                            <KeywordLink keyword={ancestor} />
                          </li>
                        );
                      })}
                  <li
                    className={`tree__item tree__item--current tree__item--indent-${numberOfAncestorLevels}`}
                  >
                    <KeywordLink keyword={keyword} />
                  </li>
                  {this.props.showDescendants != false &&
                    descendants &&
                    descendants.map((descendant, index) => {
                      return (
                        <li
                          key={index}
                          className={`tree__item tree__item--indent-${numberOfAncestorLevels +
                            Math.abs(descendant.level)}`}
                        >
                          <KeywordLink keyword={descendant} />
                        </li>
                      );
                    })}
                </ul>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default KeywordTree;
