import React, { Component } from "react";

import ResultItem from "./ResultItem";
// import { SearchOptionsContext } from "./SearchOptionsProvider";
import { ActiveKeywordContext } from "./ActiveKeywordProvider";
import ResultGroupList from "./ResultGroupList";

import OrganizationVacancyList from "./OrganizationVacancyList";

class ResultMapItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeGroupId: null
    };
  }

  // On "dot" click
  // Trigger the activateItem method of ResultMap
  // Which will activate this ResultMapItem and deactivate its siblings
  onDotClick = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.activateItem(this.props.id);

    // Deactivate all activated keywords (if any)
    let { deactivateKeyword } = this.context;
    deactivateKeyword();
  };

  // When close (X) of this item is clicked
  onCloseButtonClick = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.deactivateItem(); //this.props.id
  };

  // When group item is clicked
  onGroupClick = groupId => {
    this.setState({
      activeGroupId: groupId
    });
  };

  // When "back" link is clicked
  unfocusGroup = () => {
    this.setState({
      activeGroupId: null
    });
  };

  onClick = e => {
    e.stopPropagation();
  };

  render() {
    const node = this.props;

    const showZoomedLocation = node.zoomed && node.city == "Amsterdam";

    // Location relative to NL map in pct
    let locationX = node.coordinates ? node.coordinates.split(",")[0] : 0;
    let locationY = node.coordinates ? node.coordinates.split(",")[1] : 0;

    // For amsterdam, location is specified relative zoom area (surrounding Amsterdam center)
    // instead relative to NL map origin
    // (also when not zoomed!)
    if (node.city == "Amsterdam" && showZoomedLocation) {
      locationX = node.zoomCoordinates ? node.zoomCoordinates.split(",")[0] : 0;
      locationY = node.zoomCoordinates ? node.zoomCoordinates.split(",")[1] : 0;
    }

    const region = locationX > 75 ? "east" : locationX < 25 ? "west" : "middle";

    // Sort groupnodes alphabetically
    const groupNodes = node.groupNodes.sort((a, b) =>
      a.name < b.name ? -1 : 1
    );

    const activeGroup = this.state.activeGroupId
      ? groupNodes.find(groupNode => groupNode.id == this.state.activeGroupId)
      : null;

    // Assuming all map items are roots
    return (
      <div
        className={`mapitem ${node.active ? "mapitem--active" : null}`}
        style={{
          left: locationX + "%",
          top: locationY + "%"
        }}
        onClick={this.onClick}
      >
        <div className="mapitem__dot" onClick={this.onDotClick} />

        <div className={`mapitem__card mapitem__card--region-${region}`}>
          <div className="mapitem__card__inner">
            <a
              onClick={this.onCloseButtonClick}
              className="mapitem__close-cross"
            >
              x
            </a>

            <h3 className="mapitem__title">{node.name}</h3>

            {node.city ? (
              <p className="mapitem__location">City: {node.city}</p>
            ) : (
              <p className="mapitem__location">Unknown location</p>
            )}

            {node.active && <OrganizationVacancyList rootNode={node} />}

            {activeGroup /* If user clicked on one of the groups */ ? (
              <div>
                <a
                  onClick={this.unfocusGroup}
                  className="mapitem__active-group__back"
                >
                  &larr; Back
                </a>
                <ResultItem node={activeGroup} theme="map" />
              </div>
            ) : groupNodes.length > 0 ? (
              /* No group selected -> show list of all groups */
              node.active && (
                <ResultGroupList
                  groupNodes={groupNodes}
                  onGroupClick={this.onGroupClick}
                />
              )
            ) : null}

            <a
              onClick={this.onCloseButtonClick}
              className="mapitem__close-button"
            >
              Close
            </a>
          </div>
        </div>
      </div>
    );
  }
}

// Assign context
ResultMapItem.contextType = ActiveKeywordContext;

export default ResultMapItem;
