import gql from "graphql-tag";

export const GET_KEYWORD_DETAILS = gql`
  query keyword($keywordId: ID!) {
    keyword(keywordId: $keywordId) {
      type
      label
      groupNodeCount
      ancestors {
        level
        id
        label
      }
      descendants {
        level
        id
        label
      }
    }
  }
`;

export default GET_KEYWORD_DETAILS;
