import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// apollo/graphql
import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-boost";

const csrfToken = document
  .querySelector("meta[name=csrf-token]")
  .getAttribute("content");

export const client = new ApolloClient({
  credentials: "same-origin",
  headers: {
    "X-CSRF-Token": csrfToken
  }
});

import MainApp from "./MainApp";
import AboutUs from "./AboutUs";
import Downloads from "./Downloads";
import VacancyList, { ExpiredVacancyList } from "./VacancyList";

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Router>
          <Switch>
            <Route
              path="/about"
              render={props => (
                <MainApp {...props} contentPageComponent={AboutUs} />
              )}
            />
            <Route
              path="/vacancies"
              render={props => (
                <MainApp {...props} contentPageComponent={VacancyList} />
              )}
            />
            <Route
              path="/closed-vacancies"
              render={props => (
                <MainApp {...props} contentPageComponent={ExpiredVacancyList} />
              )}
            />
            <Route
              path="/downloads"
              render={props => (
                <MainApp {...props} contentPageComponent={Downloads} />
              )}
            />
            <Route path="*" component={MainApp} />
          </Switch>
        </Router>
      </ApolloProvider>
    );
  }
}

export default App;
