import React, { Component } from "react";
import ClearSearchLink from "./ClearSearchLink";
import { Redirect } from "react-router-dom";

import { Query } from "react-apollo";
import { GET_FULL_RESULTS, GET_PARTIAL_RESULTS } from "queries/searchQuery";

// Wait interval after change event
const SEARCH_WAIT_INTERVAL = 450;

// Wrap search query around form to be able to pass locked prop
class SearchForm extends Component {
  render() {
    const searchQueryType = this.props.searchTerm
      ? GET_FULL_RESULTS
      : GET_PARTIAL_RESULTS;

    return (
      <Query
        query={searchQueryType}
        variables={{ searchTerm: this.props.searchTerm }}
      >
        {({ loading }) => {
          return <InnerSearchForm locked={loading} {...this.props} />;
        }}
      </Query>
    );
  }
}

// The searchTerm in this Component's local state gets updated as soon as user
// types, but the app's global searchTerm will only be updated
// when this.pushNewSearchTerm() is called.

class InnerSearchForm extends Component {
  // Needs onChange prop
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: props.searchTerm,
      newSearchTerm: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchTerm !== this.props.searchTerm) {
      // search term prop changed (meaning: new search term pushed from MainApp) -> update the local state
      // console.log("Received new props -> updating searchterm state")
      this.setState({
        searchTerm: nextProps.searchTerm
      });
    }
  }

  handleSearchTermChange = e => {
    // Clear timer (don't call this.handleChange() yet)
    clearTimeout(this.timer);

    this.setState(
      {
        searchTerm: e.target.value
      },
      () => {
        // Only call this.handleChange() when user stopped typing
        this.timer = setTimeout(
          () => this.pushNewSearchTerm(),
          SEARCH_WAIT_INTERVAL
        );
      }
    );
  };

  pushNewSearchTerm = () => {
    // if an earlier search is still being executed
    if (!this.props.locked) {
      //console.log("pushing new searchTerm: ", this.state.searchTerm);

      //this.props.onChange(this.state.searchTerm);

      // setting newSearchTerm will trigger Redirect to new term
      this.setState({
        newSearchTerm: this.state.searchTerm
      })
    } else {
      // try again in 1 sec
      setTimeout(() => this.pushNewSearchTerm(), 1000);
    }
  };

  // TODO: dit wordt niet meer gebruikt?
  // clearSearchTerm = e => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   this.setState(
  //     {
  //       newSearchTerm: ""
  //     },
  //     () => {
  //       this.handleChange();
  //     }
  //   );
  // };

  render() {
    return (
      <div className="search-input-container">
        
        {this.state.newSearchTerm ? <Redirect to={this.state.newSearchTerm} /> : null }

        {this.state.searchTerm ? (
          <ClearSearchLink className="search-input-clear">x</ClearSearchLink>
        ) : null}

        <input
          className="search-input"
          id="search-input"
          type="text"
          placeholder="Example: particle physics"
          value={this.state.searchTerm}
          onChange={this.handleSearchTermChange}
        />
      </div>
    );
  }
}

export default SearchForm;
