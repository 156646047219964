import React from "react";

const Downloads = () => (
  <div className="content_page content_page--about_us">
    <h2>Downloads</h2>
    
    <h3>Brochure: Impoving diversity and inclusiveness, a collection of best practices</h3>
    <p>
      <a href="/assets/DutchPhysics_best_practices_diversity-inclusiveness2020.pdf"
         target="_blank"
         rel="noopener noreferrer">
        Download the document
      </a>
    </p>

    <h3>List: Dutch female physicists</h3>
    <p>
      <a href="/assets/Dutch_female_physicists_Dec2020.pdf"
         target="_blank"
         rel="noopener noreferrer">
        Download the document
      </a>
    </p>

  </div>
);

export default Downloads;
