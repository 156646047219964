import React, { Component } from "react";
import { SearchOptionsContext } from "./SearchOptionsProvider";

class KeywordLabel extends Component {
  constructor(props) {
    super(props);
  }

  highlightKeywordLabel(keywordLabel, searchTerm) {
    if (searchTerm.length == 0) {
      return keywordLabel;
    }
    // case insensitive regex (i)
    // match multiple (g)
    // and perserve match (parentheses)
    // by preserving match we make sure no user input (searchTerm) is inserted in the html
    var regex = new RegExp(`(${searchTerm})`, "ig");
    return keywordLabel.replace(
      regex,
      '<span class="keyword-list__item__highlighted-part">$1</span>'
    );
  }

  render() {
    const { keyword, showGroupCount } = this.props;

    return (
      <SearchOptionsContext.Consumer>
        {searchOptions => (
          <span>
            <span
              className=""
              dangerouslySetInnerHTML={{
                __html: this.highlightKeywordLabel(
                  keyword.label,
                  searchOptions.searchTerm
                )
              }}
            />{" "}
            {showGroupCount ? <span>({keyword.groupNodeCount})</span> : null}
          </span>
        )}
      </SearchOptionsContext.Consumer>
    );
  }
}

export default KeywordLabel;
