import React from "react";
import VacancyItem from "./VacancyItem";
import { Query } from "react-apollo";

import GET_ALL_VACANCIES from "../queries/vacanciesQuery";

import { Link } from "react-router-dom";


const VacancyList = (props) => {
  const listType = (props && props.listType) || 'active';
  
  return (
    <div>

      <div className="results_topbar">    
        <div className="results_topbar__toggler">
          <Link to={`/vacancies`} className={listType == 'active' ? `toggler--active` : null}>Open vacancies</Link>
          <Link to={`/closed-vacancies`} className={listType != 'active' ? `toggler--active` : null}>Closed vacancies</Link>
        </div>
      </div>

      <div className="vacancy_list">
        <Query query={GET_ALL_VACANCIES} variables={{active: listType == 'active'}}>
          {({ loading, error, data }) => {
            if (loading) {
              return <div></div>;
            }

            if (error) {
              console.log(error.graphQLErrors);
              return (
                <div>Sorry, something went wrong. Couldn't load vacancies.</div>
              );
            }

            if (data.vacancies.length == 0) {
              return <div>No vacancies {listType == 'active' ? 'availble currently' : 'found' }.</div>;
            }

            return data.vacancies.map((vacancy, idx) => <VacancyItem vacancy={vacancy} key={idx} />)

          }}

        </Query>
      </div>

    </div>
  )
}

export const ExpiredVacancyList = () => {
  return <VacancyList listType='expired' />
}


export default VacancyList;