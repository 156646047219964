import gql from "graphql-tag";

const GET_SUGGESTIONS = gql`
  query suggestions {
    suggestions {
      id
      label
      groupNodeCount
      parents {
        id
        label
        type
        groupNodeCount
      }
      children {
        id
        label
        type
        groupNodeCount
      }
    }
  }
`;

export default GET_SUGGESTIONS;
