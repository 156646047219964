import gql from "graphql-tag";

const GET_VACANCIES_BY_NODE = gql`
  query vacanciesByNodeQuery($nodeId: ID!) {
    vacancies(nodeId: $nodeId) {
      title
      url
    }
  }
`;

export default GET_VACANCIES_BY_NODE;
