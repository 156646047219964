import React, { Component } from "react";

import ResultMapItem from "./ResultMapItem";

import mapImage from "images/map.png";

class ResultMap extends Component {
  constructor(props) {
    super(props);

    const activeNodeId = null;

    this.state = {
      mapWithCities: false,
      activeNodeId: activeNodeId,
      zoomed: false
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown, false);
  }

  onKeyDown = e => {
    // on press 9
    if (e.keyCode === 57) this.setState({ mapWithCities: true });
  };

  activateItem = nodeId => {
    this.setState({
      activeNodeId: nodeId
    });
  };

  deactivateItem = () => {
    this.setState({
      activeNodeId: null
    });
  };

  onClick = e => {
    e.preventDefault();
    this.activateItem();
  };

  onZoomAreaEnter = () => {
    this.setState({
      zoomed: true
    });
  };

  onZoomAreaLeave = () => {
    this.setState({
      zoomed: false
    });
  };

  render() {
    const groupNodes = this.props.groupNodes;

    const rootNodes = this.props.rootNodes;

    // console.log("rootNodes", rootNodes);

    const amsterdamRootNodes = rootNodes.filter(v => v.city == "Amsterdam");
    const nonAmsterdamNodes = rootNodes.filter(v => v.city != "Amsterdam");

    return (
      <div className="map_container" onClick={this.onClick}>
        <div
          className={`map ${
            this.state.mapWithCities ? "map--with-cities" : ""
          }`}
        >
          <img className="map__image" src={mapImage} />
          <div
            className={`map_zoomed_amsterdam ${
              this.state.zoomed ? "map_zoomed_amsterdam--active" : ""
            }`}
            onMouseEnter={this.onZoomAreaEnter}
            onMouseLeave={this.onZoomAreaLeave}
          >
            {amsterdamRootNodes
              ? amsterdamRootNodes.map((node, index) => (
                  <ResultMapItem
                    key={node.id}
                    {...node}
                    zoomed={this.state.zoomed}
                    activateItem={this.activateItem}
                    deactivateItem={this.deactivateItem}
                    active={this.state.activeNodeId == node.id}
                  />
                ))
              : null}
          </div>

          {nonAmsterdamNodes
            ? nonAmsterdamNodes.map((node, index) => (
                <ResultMapItem
                  key={node.id}
                  {...node}
                  zoomed={this.state.zoomed}
                  activateItem={this.activateItem}
                  deactivateItem={this.deactivateItem}
                  active={this.state.activeNodeId == node.id}
                />
              ))
            : null}
        </div>
      </div>
    );
  }
}

export default ResultMap;
