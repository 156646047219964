import gql from "graphql-tag";

// Groups + Institutions
const baseQuery = `
  keywords {
    id
    label
    groupNodeCount
  }
  rootNodes {
    id
    name
    city
    coordinates
    zoomCoordinates
  }
`;

// TODO: ancestors nu toegevoegd, maar dat wordt wel een grote query...
// ancestorts zijn nodig om kaartje met structuur te tonen.
const partialQuery =
  baseQuery +
  `
  nodes {
    id
    name
    piName
    city
    url
    rootId
    isGroup

    ancestors {
      id
      name
      url
      depth
    }
  }
`;

const fullQuery =
  baseQuery +
  `
  nodes {
    id
    name
    piName
    url
    rootId
    isGroup
  
    keywords {
      id
      label
      type
      groupNodeCount
    }

    children {
      id
      name
    }

    ancestors {
      id
      name
      url
      depth
    }
  }
`;

export const GET_PARTIAL_RESULTS = gql`
  query simpleSearch($searchTerm: String!) {
    search(searchTerm: $searchTerm) {
      ${partialQuery}
    }
  }
`;

export const GET_FULL_RESULTS = gql`
  query search($searchTerm: String!) {
    search(searchTerm: $searchTerm) {
      ${fullQuery}
    }
  }
`;

export default GET_FULL_RESULTS;
