import gql from "graphql-tag";

export const GET_NODE_DETAILS = gql`
  query node($nodeId: ID!) {
    node(id: $nodeId) {
      id
      name
      piName
      city
      coordinates
      zoomCoordinates
      nodeTypeName
      url
      rootId

      keywords {
        id
        label
        type
        groupNodeCount
      }

      children {
        id
        name
      }

      ancestors {
        id
        name
        url
        depth
      }
    }
  }
`;

export default GET_NODE_DETAILS;
