import React from "react";

// TODO: css 

const VacancyItem = (props) => {
  const vacancy = props.vacancy;
  const timestamp = Date.parse(vacancy.createdAt);
  const date = new Date(timestamp);

  return (
    <div className="vacancy-item ">  
      <h3 className="vacancy-item__title">{vacancy.title}</h3>
      <p className="vacancy-item__textline">{vacancy.organizationName}</p>
      <p className="vacancy-item__textline">Added on {date.toLocaleDateString()}</p>
      <p></p>
      <div className="vacancy-item__visit-website-container">
        <a
          className="vacancy-item__visit-website"
          href={vacancy.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit website
          </a>
      </div>
    </div>
  )
}

export default VacancyItem;