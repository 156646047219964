import React from "react";
import { Link } from "react-router-dom";

const FooterMenu = (props) => (
  <div className={`footer_menu ${props.className}`}>
    <ul className="footer_menu__list">
      <li className="footer_menu__list__item">
        <Link to="/about" className="footer_menu__list__link">
          About Us
        </Link>
      </li>

      <li className="footer_menu__list__item">
        <Link to="/vacancies" className="footer_menu__list__link">
          Vacancies
        </Link>
      </li>

      <li className="footer_menu__list__item">
        <Link to="/downloads" className="footer_menu__list__link">
          Downloads
        </Link>
      </li>
    </ul>
  </div>
);

export default FooterMenu;
