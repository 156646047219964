import React, { Component } from "react";

class ResultViewToggler extends Component {
  // Needs onChange prop
  constructor(props) {
    super(props);
  }

  onMapClick = e => {
    this.props.onToggle("map");
  };

  onListClick = e => {
    this.props.onToggle("list");
  };

  render() {
    return (
      <div className="results_topbar__toggler">
        <a
          onClick={this.onMapClick}
          className={this.props.current == "map" ? "toggler--active" : null}
        >
          View on map
        </a>
        <a
          onClick={this.onListClick}
          className={this.props.current == "list" ? "toggler--active" : null}
        >
          View as list
        </a>
      </div>
    );
  }
}

export default ResultViewToggler;
