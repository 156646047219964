import React, { Component } from "react";
import { Query } from "react-apollo";

import GET_VACANCIES_BY_NODE from "../queries/vacanciesByNodeQuery";

// Props: props.rootNode

class OrganizationVacancyList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props;

    return (
      <div>
        <Query query={GET_VACANCIES_BY_NODE} variables={{ nodeId: props.rootNode.id }}>
          {({ loading, error, data }) => {
            if (loading) {
              return <div></div>;
            }

            if (error) {
              console.log(error.graphQLErrors);
              return (
                <div>Sorry, something went wrong. Couldn't load vacancies.</div>
              );
            }

            if (data.vacancies.length == 0) {
              return <div />;
            }

            return (
              <div>
                <p className="mapitem__vacancy-list-title">Vacancies: </p>
                <ul className="mapitem__vacancy-list">
                  {data.vacancies.map(vacancy => {
                    return (
                      <li className="mapitem__vacancy-list__item">
                        <a
                          href={vacancy.url}
                          className="mapitem__vacancy-list__link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {vacancy.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default OrganizationVacancyList;
