import React, { Component } from "react";

import KeywordListItem from "./KeywordListItem";

import { Query } from "react-apollo";
import GET_SUGGESTIONS from "queries/suggestionsQuery";

class KeywordSuggestions extends Component {
  constructor(props) {
    super(props);

    //this.props = props;
  }

  render() {
    const { showTitle, hideOnMobile = false } = this.props;

    return (
      <Query query={GET_SUGGESTIONS}>
        {({ loading, error, data }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <p>Error :(</p>;
          const { suggestions } = data;

          return (
            <div>
              {showTitle ? (
                <h3 className="subtitle-suggestions">Suggestions:</h3>
              ) : null}
              <ul
                className={`keyword-list ${
                  hideOnMobile ? "keyword-list--hide-on-small-screen" : ""
                }`}
              >
                {suggestions
                  ? suggestions.map((suggestion, index) => (
                      <KeywordListItem
                        theme="suggestion"
                        key={suggestion.id}
                        keyword={suggestion}
                        listName="suggestions"
                      />
                    ))
                  : null}
              </ul>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default KeywordSuggestions;
